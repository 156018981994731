import { graphql } from "gatsby";

import { List } from "../../components/List";
import { PageSection, PageSectionContainer } from "../../components/PageSection";
import React from "react";
import { Head } from "../../components/Head";
import { kebabCase } from "lodash";
import styled from "styled-components";
import { Heading } from "../../components/Typography/Heading";
import { Hyperlink } from "../../components/Link/Hyperlink";

const Title = styled.span`
    text-transform: capitalize;
    text-decoration: none;
`;

const TagListInner = styled(List)`
    margin-top: ${(p) => p.theme.grid.gutters.m};
`;
const Container = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-left: min(48px);
    padding-right: min(48px);

    @media (max-width: ${(p) => p.theme.breakpoint.xl}) {
        padding-left: none;
        padding-right: none;
    }
`;


const TagsList = ({ data }) => {
    const { nodes } = data.allSanityTag;

    const tagElements = nodes.map(({ title }) => (
        <li>
            <Hyperlink href={`/blog/tagged/${kebabCase(title)}/`}>
                <Title>{title}</Title>
            </Hyperlink>
        </li>
    ));

    // this page is no-indexed because it keeps getting dinged by SEO
    // for low word count
    return (
        <>
            <Head title="Easy Agile Blog Tags" preventIndex={true} />

            <Container>
                <Heading level="h1" levelStyle="h3">Easy Agile Blog Tags</Heading>
                View Easy Agile blogs by interest.

                <TagListInner>{tagElements}</TagListInner>
            </Container>
        </>
    );
};

export default TagsList;

export const tagsListQuery = graphql`
    query TagTemplatePage {
        allSanityTag(sort: { fields: title, order: ASC }) {
            nodes {
                id
                title
            }
        }
    }
`;
